import React, { useState, useEffect } from 'react';
import { withErrorBoundary } from '@/utils/errors';

import Link from 'next/link';

import styles from './Links.module.scss';

const Links = () => {
  useEffect(() => {
    setMounted(true);
  }, []);

  const [mounted, setMounted] = useState(false);

  return (
    mounted && (
      <div className={styles.linksWrapper}>
        <Link replace href="/terms-of-use" className="text-muted">
          Terms of Use
        </Link>
        <Link replace href="/privacy-policy" className="text-muted">
          Privacy Policy
        </Link>
        <br />
        <Link href="/contact-us" className="text-muted">
          Contact Us
        </Link>
      </div>
    )
  );
};

export default withErrorBoundary(Links);
