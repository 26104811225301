import React from 'react';
import { withErrorBoundary } from '@/utils/errors';
import classNames from 'classnames';

import styles from './Loader.module.scss';

const Loader = ({ loading, fullHeight, smallHeight, miniHeight }) =>
  loading ? (
    <div
      className={classNames(
        styles.loaderWrapper,
        fullHeight && styles.fullHeight,
        smallHeight && styles.smallHeight,
        miniHeight && styles.miniHeight
      )}
    >
      <div className={classNames(styles.customLoader, miniHeight && styles.miniHeight)}>
        <div className={styles.customLoader__bar}></div>
        <div className={styles.customLoader__bar}></div>
        <div className={styles.customLoader__bar}></div>
        <div className={styles.customLoader__bar}></div>
      </div>
    </div>
  ) : null;

export default withErrorBoundary(Loader);
